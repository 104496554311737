.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.changePlan {
  width: 100%;
  max-width: 1300px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.treatmentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  max-width: 1300px;
  height: 100%;
}

.heading h2 {
  margin-right: 20px;
}

@media (max-width: 786px) {
  .changePlan {
    flex-direction: column;
    gap:2rem;
  }
}
