.inputs{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.inputs>label{
    /* width: 20%; */
    width: max-content;
}

.photoFields{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: smaller;
    text-transform: uppercase;
    font-weight: 400;
}

.questions{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    flex-wrap: wrap;
}

.questionLabel{
    width:70%;
}

.questionOptions{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
}

.changePlan {
    width: 100%;
    max-width: 1300px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  @media (max-width: 786px) {
    .inputs {
      /* justify-content: center; */
      flex-direction: column;
    }
    .changePlan{
        flex-direction: column;
        gap: 1rem;
    }

    .inputs>div>div{
        /* flex: 1; */
        display: flex;
        justify-content: center;
    }
  }