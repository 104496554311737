.container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  padding: 0 30px;
  /* margin-top: 200px; */
  /* margin-bottom: 20px; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px; */
}

.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.formTitle{
  text-align: center;
}

.inputField {
  margin-bottom: 20px;
  width: fit-content !important;
}

.submit{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-bottom: 1rem;
}

.container{
  height: 100vh;
  overflow-y: scroll;
}
