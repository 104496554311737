.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.subContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 98%;
}

@media (max-width: 786px) {
    .headerContainer {
      flex-direction: column;
    }
  }
