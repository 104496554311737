.elementContainer {
  display: flex;
  font-size: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.elementContainer h3 {
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.subscriptionContainer,
.generalContainer {
  border: 1px solid rgba(131, 122, 122, 0.507);
  width: 420px;
  max-width: 100%;
  display: flex;
  /* justify-content: center; */
}
.subscriptionContainer{
  justify-content: center;
}
.generalElement {
  padding: 15px;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
}
.element {
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.emiCycle {
  padding: 8px;
  width: 20px;
  height: 20px;
  font-size: 16px;
  text-align: center;
  margin-right: 20px;
  border-radius: 50%;
}

.tp_elements{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

.paymentDataPaper{
  
    display: grid;
    /* // flexDirection: `row`, */
    /* // flexWrap: `wrap`, */
    padding: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    /* // gap:`1rem` */
}

.formControl{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

  
@media (max-width: 786px) {
  .paymentDataPaper {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .formControl{
    flex-direction: column;
  }
}
/*  */
