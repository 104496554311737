.boxContainer {
    display: flex;
  }
  .inputBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .heading {
    display: flex;
    align-items: center;
  }
  .formContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
    padding: 0 15px;
    height: 100vh;
    overflow-y: scroll;
  }
  .formBody {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .fileUpload {
    max-width: 400px;
    width: 100%;
  }
  .formInpFields {
    width: 100%;
    margin-right: 60px;
    min-width: 500px;
    max-width: 600px;
    padding-bottom: 25px;
  }
  @media (max-width: 1170px) {
    .formBody {
      flex-direction: column;
    }
    .formInpFields {
      max-width: 700px;
      margin-right: 0px;
    }
    .fileUpload {
      max-width: 700px;
    }
    .files{
        margin-top: 50px;
    }
  }
  
  @media (max-width: 786px) {
    .inputBox {
      justify-content: center;
      flex-direction: column;
    }
  }