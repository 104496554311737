.imageContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

@media (max-width:786px) {
    .imageContainer{
        grid-template-columns: 1fr;
    }
}