.container,
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.formContainer {
  width: 500px;
  padding: 30px;
  margin-top: 100px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

.formTitle{
  margin-top: 10px;
}

.inputField {
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .formContainer {
    width: 256px;
  }
}
