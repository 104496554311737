.formContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.formContainer{
    /* width: 40%; */
}
.formInput{
    /* margin-top: 10px!important; */
    width: 70%;
}
.acceptFile{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.acceptFile b{
    margin-bottom: 10px;
}

.container{
    width: 80%;
    padding: 30px;
    /* margin-top: 100px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    /* height: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rootContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 968px) {
    .formInput{
        /* margin-top: 10px!important; */
        width: 100%;
    }
  }