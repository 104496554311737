.container{
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
}
.diagnosticImg{
    margin-top: 30px;
    width:200px;
    height: 200px;
}
.imgBox{
    padding: 40px;
}