.commentBox{
    display: flex;
    align-items: center;
}
.noBold{
    font-weight: normal;
}
.updateBox{
    display: flex;
    align-items: flex-start;
    border: 1px solid rgba(128, 128, 128, 0.226);
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    /* width: 800px; */
}

@media (max-width: 786px) {
    .commentBox{
        flex-direction: column;
        gap:1rem;
    }
}