.fileBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.fileName {
  /* font-weight: bold; */
  margin-right: 10px;
}
.labelContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}
.labelContainer:nth-child(1) {
  margin-top: 20px;
}
.fileContainer {
  width: 100%;
  padding: 0px;
}
