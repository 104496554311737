.noLinkStyle {
  text-decoration: none !important;
  color: black !important;
}
.heading{
  padding: 8px 16px;
  font-weight: bolder;
}

.sidebar {
  /* width: 240px; */
}

@media (max-width: 600px) {
  .sidebar {
    width: 0;
  }
}